import React from "react";
import { useTranslation } from "react-i18next";
import './App.css';
import Maps from './Maps';

export default function App() {
  const { t } = useTranslation();
  return (
      <div>
        <div id="Welcome">
  
          <nav className="navbar navbar-expand-lg navbar fixed-top  navbar-dark bg-dark">
          <a className="navbar-brand" href="#Welcome">
              <img src="assets/images/logo.jpg" width="75" height="75" className="d-inline-block" alt="" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="#Welcome">Welcome</a>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link" href="assets/images/khoiMenu.pdf" alt="mainMenu">Menu</a>
                </li>
                {/*<li className="nav-item">
                  <a className="nav-link" href="assets/images/KhoiLunchMenu.pdf" alt="lunchMenu">Lunch menu</a>
  </li>*/}
                <li className="nav-item">
                  <a className="nav-link" href="https://qopla.com/restaurant/kh%C3%B3i-restaurant/qomEMY2zaK/order">Order takeaway</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.foodora.se/en/restaurant/gst5/khoi-restaurang">Foodora</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#OurLocation">Location</a>
                </li>
              </ul>
          </div>
        </nav>
          <div className="parallax d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center justify-content-center mw-60">
                  <div className="logo">
                    <img src="assets/images/logo.png" className="img-fluid" alt="Khoi" />
                    <br />
                    <br />
                    <div className="row">
                    <div className="col">
                      <p className="text-center">Follow us:     
                        <a className="social-icon" href="https://www.facebook.com/khois.restaurant"><i className="fab fa-facebook-f fa-lg"></i></a> 
                        <a className="social-icon" href="https://www.instagram.com/khois.restaurant"><i className="fab fa-instagram fa-lg"></i></a>
                        <a className="social-icon" href="https://www.borascity.se/foretag/khoi-restaurant"><i className="fas fa-bold fa-lg"></i></a></p>
                    </div>
                  </div>
                  </div>

              </div>
          </div>
            
        </div>
        <div className="container">
        
          <div className="row rounded-bottom">
          <div className="btn-group-vertical mx-auto" id= "Order"> 
          <br />
              <a href="assets/images/khoiMenu.pdf"  alt="Menu" className="button">Menu</a>
              {/*<a href="assets/images/KhoiLunchMenu.pdf"  alt="lunchMenu" className="button">Lunch menu</a>*/}
              <a href="https://qopla.com/restaurant/kh%C3%B3i-restaurant/qomEMY2zaK/order" className="button eatherePickup">Order take away</a>
              <a href="https://www.foodora.se/en/restaurant/gst5/khoi-restaurang" className="button">Foodora</a>
          </div>
          </div>

        
        <div className="row" id="Our Story">
          <div className="col navMenu">
                <h2 className="text-center">Our Story</h2>
          </div>
        </div>
        
        <div className="row rounded">
          <div className="d-grid gap-2 col-8 mx-auto col-xs-1" align="center">
            <br />
            <h5>Khoi' is a  Vietnamese Restaurant offering an array of fresh tasting culinary from scrumptious eats and salads, to heart-warming bowls of “Pho”. 
Our aims bringing to life the overall vision of  the authentic feeling of Vietnam for guests whilst dining at Khoi'.</h5>
            <br />
            <br />
          </div>       
        </div>
        <br />
        <div className="row" id="OurLocation">
          <div className="col navMenu">
            <h2 className="text-center">Our location</h2>
          </div>
        </div>

            <div className="row bg-light rounded row justify-content-center">
              <div className="col-md-10 mx-auto">
                  <Maps />
              </div>  
            </div> 
            <div className="row bg-light rounded row justify-content-center">
              <div className="col mx-auto">
              </div>  
            </div> 
    <div className="row footer bg-dark">
          <div className="col">
            <p className="text-center">Follow us:     
              <a className="social-icon" href="https://www.facebook.com/khois.restaurant"><i className="fab fa-facebook fa-lg"></i></a> 
              <a className="social-icon" href="https://www.instagram.com/khois.restaurant"><i className="fab fa-instagram fa-lg"></i></a>
              <a className="social-icon" href="https://www.borascity.se/foretag/khoi-restaurant"><i className="fas fa-bold fa-lg"></i></a></p>
          </div>
        </div>
        </div>
  </div>
  );
}